
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import apiDrive from "@/core/services/Drive";
import { useI18n } from "vue-i18n";

export default defineComponent({
    name: "administration-documentcategories",
    components: {},
    data() {
        return {
            categories: [],
            laoding: true,
        };
    },
    methods: {
        fetchCategories() {
            apiDrive.getCategories().then((response) => {
                this.categories = response.data.categories;
                this.laoding = false;
            });
        },
        deleteCategory(id: number) {
            apiDrive.deleteCategory(id).then(() => location.reload());
        },
    },
    setup() {
        const { t } = useI18n();
        setCurrentPageBreadcrumbs(t("pages.driveManagement.categories.management"), []);
        return { t };
    },
    watch: {
        "$i18n.locale": function () {
            setCurrentPageBreadcrumbs(this.t("pages.driveManagement.categories.management"), []);
        },
    },
    created() {
        this.fetchCategories();
    },
});
